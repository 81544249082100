import React, { useEffect } from 'react'
import { Helmet } from 'gatsby-plugin-react-i18next'

import { useTranslation } from 'react-i18next'
import { graphql, PageProps } from 'gatsby'
import { OpenDayQuery } from '@graphql-types'

const CourseOpenDayPage = (props: PageProps<OpenDayQuery>) => {
	const { t } = useTranslation()

	useEffect(() => {
		let link =
			props.data.openDayEvent != null ? props.data.openDayEvent.url : null
		if (link == null) {
			link =
				'https://www.youtube.com/playlist?list=PLjB7odcPYBI0O0A_YJquj5G-_PIUop9oT'
		}

		window.location.href = link
	}, [])

	let link =
		props.data.openDayEvent != null ? props.data.openDayEvent.url : null
	if (link == null) {
		link =
			'https://www.youtube.com/playlist?list=PLjB7odcPYBI0O0A_YJquj5G-_PIUop9oT'
	}

	return (
		<Helmet
			title={t('courses:common.open_day')}
			meta={[
				{
					'http-equiv': `refresh`,
					content: `0; url=${link}`,
				},
			]}
		/>
	)
}

export default CourseOpenDayPage

/* eslint-disable */
export const query = graphql`
	query OpenDay($language: String!) {
		...TranslationFragment
		site {
			siteMetadata {
				title
				description
				author
			}
		}
		...MAPCoursePageFragment
	}
`
